import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {toPlainText} from '../lib/helpers'
import AuthorPage from '../components/author-page'

export const query = graphql`
  query AuthorTemplateQuery($id: String!) {
    author: sanityAuthor(
      id: {eq: $id}
      ) {
        name
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        _rawBio
        slug {
          current
        }
      }
    }
`

const AuthorTemplate = props => {
  const {data, errors} = props
  const author = data && data.author
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {author && <SEO title={author.name || 'Untitled'} bio={toPlainText(author._rawBio)} image={author.image} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {author && <AuthorPage {...author} />}
    </Layout>
  )
}

export default AuthorTemplate
