import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getBlogUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'

import styles from './author-page.module.css'

function AuthorPage (props) {
  const {name, image, _rawBio, slug} = props
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{name}</h1>
            {_rawBio && <PortableText blocks={_rawBio} />}
          </div>
          <aside className={styles.metaContent}>
          {image && image.asset && (
        <div className={styles.image}>
          <img
            src={imageUrlFor(buildImageObj(image))
              .width(300)
              .height(300)
              .fit('crop')
              .auto('format')
              .url()}
            alt={image.alt}
          />
          <div>{image.caption}</div>
        </div>
      )}
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default AuthorPage
